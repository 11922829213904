import { AppDataFormat, ProjectStatus } from './types';

export const data: AppDataFormat[] = [{
    title: 'Chat bot',
    text: 'Ask us your questions',
    icon: 'chatbot',
    url: 'https://be-intelli.com/chatbot/'
}, {
    title: 'Perception App',
    text: 'How an autonomous car sees the world',
    icon: 'perception',
    status: ProjectStatus.IN_PROGRESS,
    url: 'https://be-intelli.com'
}, {
    title: 'Platform Web App',
    text: 'Tangible Mobility Data',
    icon: 'platform',
    status: ProjectStatus.IN_PROGRESS,
    url: 'https://be-intelli.com'
}, {
    title: 'Momo',
    text: 'Explore VMZ Mobility Display',
    icon: 'momo',
    status: ProjectStatus.IN_PROGRESS,
    url: 'https://be-intelli.com'
}, {
    title: 'Beintelli Data Manifesto',
    text: 'Our guidelines for high quality data',
    icon: 'manifesto',
    status: ProjectStatus.FUTURE_DEVELOPMENT,
    url: 'https://be-intelli.com'
}, {
    title: 'Air Quality / Emissions',
    text: 'Monitoring the air on our test track',
    icon: 'air-quality',
    status: ProjectStatus.FUTURE_DEVELOPMENT,
    url: 'https://be-intelli.com'
}, {
    title: 'Autonomous Delivery',
    text: 'The future of last-mile transport',
    icon: 'robot',
    status: ProjectStatus.FUTURE_DEVELOPMENT,
    url: 'https://be-intelli.com'
}, {
    title: 'Beintelli Data Catalog',
    text: 'Create value with our vehicles data',
    icon: 'data-catalog',
    status: ProjectStatus.FUTURE_DEVELOPMENT,
    url: 'https://be-intelli.com'
}, {
    title: 'Bus Timetable / Location',
    text: 'Check where is our bus at anytime',
    icon: 'timetable',
    status: ProjectStatus.IN_PROGRESS,
    url: 'https://be-intelli.com'
}, {
    title: 'Xray Bus',
    text: 'Our bus from the inside out',
    icon: 'xray-bus',
    status: ProjectStatus.IN_PROGRESS,
    url: 'https://be-intelli.com'
}];
