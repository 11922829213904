import * as React from 'react';
import { IPrimaryCardButtonProps } from './types';
import { Link } from "react-router-dom";
import { IconComponent } from '../..';
import './PrimaryCardButton.css';

export class PrimaryCardButton extends React.Component<IPrimaryCardButtonProps> {
  render(): JSX.Element {
    const { iconHeight, iconWidth, text, url } = this.props;

    return (
      <div className='button-card-primary'>
        <Link className='link-card-primary' to={url}>
          <p className='button-card-primary-text'>{text}</p>
          <div className='button-card-primary-icon'>
            <IconComponent width={iconWidth} height={iconHeight} name='chevron-right' />
          </div>
        </Link>
      </div>
    );
  }
}