import { AppDataFormat } from './types';

export const data: AppDataFormat[] = [{
    title: 'Applications',
    text: 'Try our interactive apps',
    icon: 'applications',
    url: 'info/applications'
}, {
    title: 'Events',
    text: 'Check out our Calendar',
    icon: 'events',
    url: 'https://be-intelli.com/pressespiegel/'
}, {
    title: 'News',
    text: 'Beintelli in the media',
    icon: 'news',
    url: 'https://be-intelli.com/news/',
}, {
    title: 'Beintelli Project',
    text: 'Everything about Beintelli',
    icon: 'beintelli-arrow',
    url: 'info/beintelli'
}];



