import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Route,
  Routes,
  HashRouter as Router,
} from 'react-router-dom';
import './App.css';

import {
  LandingRoute,
  InfoRoute
} from './routes';

class App extends React.Component {
    render(): JSX.Element {
      return (
        <Router>
            <Routes>
              <Route path="/" element={<LandingRoute />} />
              <Route path="/info/:type" element={<InfoRoute />} />
            </Routes>
        </Router>
      );
    }
  }

ReactDOM.render(
    <App />,
    document.getElementById("app")
);
