import * as React from 'react';
import { Link } from "react-router-dom";
import { InfoCardComponentProps } from './types';
import './InfoCard.css';
import { LabelComponent, IconComponent } from '..';
import { ProjectStatus } from '../../data/types';

export class InfoCard extends React.Component<InfoCardComponentProps> {
    render(): JSX.Element {
        const { type, title, text, icon, url, label } = this.props;

        return <a className='card-secondary' href={url} target="_blank">
            <div className={`card-secondary-label ${!label && 'card-secondary-label-visibility'}`}>
                <LabelComponent text={label || ProjectStatus.NOT_DEFINED} type={label === ProjectStatus.IN_PROGRESS ? 'primary' : 'secondary'} />
            </div>
            <div className='card-secondary-upper-section'>
                <IconComponent name={icon} />
            </div>
            <div className='card-secondary-lower-section'>
                <h2>{title}</h2>
                <p>{text}</p>
            </div>
        </a>
    }
}
