import { IconNames } from '../components/icon/types';

export enum ProjectStatus {
    NOT_DEFINED = 'Not Defined',
    IN_PROGRESS = 'In Progress',
    FUTURE_DEVELOPMENT = 'Future Development'
}

export interface AppDataFormat {
    title: string;
    text: string;
    icon: IconNames;
    status?: ProjectStatus;
    url?: string;
}

export interface ButtonDataFormat{
    title:string, 
    url?: string,
}