import * as React from 'react';
import { ContentComponentProps } from './types';
import './Content.css';

export class Content extends React.Component<ContentComponentProps> {
    render(): JSX.Element {

        return (<section className='app-content'>
            {this.props.children}
        </section>);
    }
}
