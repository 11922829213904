import * as React from 'react';
import { LabelComponentProps } from './types';
import './Label.css';

export class Label extends React.Component<LabelComponentProps> {
    render(): JSX.Element {
        const { type, text } = this.props;
        const _type = type || 'primary'

        return <div className={`label label-${_type}`}>
            <p className='label-content'>{text}</p>
        </div>
    }
}
