import * as React from 'react';
import './Landing.css';
import {
    LandingSecondaryCardComponent,
    LandingPrimaryCardComponent,
    ContentComponent,
    RoundedIconComponent,
    IconComponent
} from '../../components';
import { LandingRouteData } from '../../data';
import { AppDataFormat } from '../../data/types';

export class Landing extends React.Component {
    render(): JSX.Element {
        const cards: AppDataFormat[] = LandingRouteData;
        const icons = cards.map(card => { return (card.icon) })
        const application_card = cards.filter(l => {
            return l.title.toLowerCase().match('applications');
        })

        return (
            <ContentComponent>
                <div className='section-logo-container'>
                    <IconComponent width={1300} height={300} name='beintelli-logo' />
                </div>

                <div className='section-description-container'>
                    <p>BeIntelli is a showcase project for autonomous driving and makes the development of complex technologies and the implementation of smart vehicles and infrastructure tangible for everyone. </p>
                    <h4>Discover BeIntelli here!</h4>
                </div>

                <div className='section-application-card'>
                    <LandingPrimaryCardComponent title={application_card[0].title} text={application_card[0].text} url={application_card[0].url} key={0} />
                </div>

                <div className='section-secondary-cards'>
                    {cards.filter(card => card.title != 'Applications').map((card, i) => {
                        return (
                            <span className='secondary-card'>
                                <LandingSecondaryCardComponent title={card.title} text={card.text} url={card.url} key={i} icon={card.icon} />
                            </span>
                        )
                    })}
                </div>

                <div className='section-social-media-links'>
                    <span className='social-media-link-wrapper'>
                        <RoundedIconComponent icon='twitter' url='https://twitter.com/beintelli?lang=en' />
                    </span>
                    <span className='social-media-link-wrapper'>
                        <RoundedIconComponent icon='instagram' url='https://www.instagram.com/beintelli/' />
                    </span>
                    <span className='social-media-link-wrapper'>
                        <RoundedIconComponent icon='linkedin' url='https://de.linkedin.com/company/be-intelli?trk=public_post_feed-actor-name' />
                    </span>
                </div>
            </ContentComponent>
        )
    }
}
