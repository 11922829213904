import * as React from 'react';
import { Link } from "react-router-dom";
import { LandingPrimaryCardProps } from './types';
import './PrimaryCard.css';
import { PrimaryCardButtonComponent } from '../..';

export class LandingPrimaryCard extends React.Component<LandingPrimaryCardProps> {
    render(): JSX.Element {
        const { title, text, url, label } = this.props;

        return <Link className='application-card-link' to={url}>
            <section className='application-card-wrapper'>
                <div className='card-upper-section'>
                    <div className='icon-perception'>
                        <img src={require(`../../../assets/icons/perception.svg`).default} />
                    </div>

                    <div className='icon-xray-bus'>
                        <img src={require(`../../../assets/icons/xray-bus.svg`).default} />
                    </div>

                    <div className='icon-momo'>
                        <img src={require(`../../../assets/icons/momo.svg`).default} />
                    </div>

                    <div className='icon-robot'>
                        <img src={require(`../../../assets/icons/robot.svg`).default} />
                    </div>

                    <div className='icon-chatbot'>
                        <img src={require(`../../../assets/icons/chatbot.svg`).default} />
                    </div>

                    { /** Blurred icons start */}
                    <div className='icon-blurred-ellipse-1'>
                        <img src={require(`../../../assets/icons/ellipse-1.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-2'>
                        <img src={require(`../../../assets/icons/ellipse-2.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-3'>
                        <img src={require(`../../../assets/icons/ellipse-3.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-4'>
                        <img src={require(`../../../assets/icons/ellipse-4.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-5'>
                        <img src={require(`../../../assets/icons/ellipse-5.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-6'>
                        <img src={require(`../../../assets/icons/ellipse-6.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-6-duplicate'>
                        <img src={require(`../../../assets/icons/ellipse-6.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-7'>
                        <img src={require(`../../../assets/icons/ellipse-7.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-8'>
                        <img src={require(`../../../assets/icons/ellipse-8.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-9'>
                        <img src={require(`../../../assets/icons/ellipse-9.svg`).default} />
                    </div>

                    <div className='icon-blurred-ellipse-10'>
                        <img src={require(`../../../assets/icons/ellipse-10.svg`).default} />
                    </div>

                    <div className='icon-blurred-data-catalog'>
                        <img src={require(`../../../assets/icons/data-catalog.svg`).default} />
                    </div>

                    <div className='icon-blurred-air-quality'>
                        <img src={require(`../../../assets/icons/air-quality.svg`).default} />
                    </div>

                    <div className='icon-blurred-timetable'>
                        <img src={require(`../../../assets/icons/timetable.svg`).default} />
                    </div>

                    <div className='icon-blurred-platform'>
                        <img src={require(`../../../assets/icons/platform.svg`).default} />
                    </div>

                    <div className='icon-blurred-manifesto'>
                        <img src={require(`../../../assets/icons/manifesto.svg`).default} />
                    </div>

                    <div className='icon-blurred-path-0'>
                        <img src={require(`../../../assets/icons/path-0.svg`).default} />
                    </div>
                    { /** Blurred icons end */}
                </div>

                <div className='card-lower-section'>
                    <h2 className='application-title'>{title}</h2>
                    <hr className='application-card-primary-hr' />
                    <PrimaryCardButtonComponent iconWidth={45} iconHeight={45} url={url} text={text} />
                </div>
            </section>
        </Link>
    }
}
