import * as React from 'react';
import { Link } from "react-router-dom";
import { RoundedIconProps } from './types';
import './RoundedIcon.css';
import { IconComponent } from '..';

export class RoundedIcon extends React.Component<RoundedIconProps> {
    render(): JSX.Element {
        const { url, icon } = this.props;

        return <a className='rounded-icon-link' href={url} target="_blank" rel="noopener noreferrer">
            <IconComponent width={40} height={40} name={icon} />
        </a>;
    }
}
