import * as React from 'react';
import { ISecondaryCardButtonProps } from './types';
import { Link } from "react-router-dom";
import { IconComponent } from '../..';
import './SecondaryCardButton.css';

export class SecondaryCardButton extends React.Component<ISecondaryCardButtonProps> {
  render(): JSX.Element {
    const { iconHeight, iconWidth, text, url } = this.props;

    return (
      <div className='button-card-secondary'>
        <Link className='link-card-secondary' to={url}>
          <p className='button-card-secondary-text'>
            {text}
          </p>
          <div className='button-card-secondary-icon'>
            <IconComponent width={iconWidth} height={iconHeight} name='chevron-right' />
          </div>
        </Link>
      </div>
    );
  }
}