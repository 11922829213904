import * as React from 'react';
import { Link } from "react-router-dom";
import { LandingSecondaryCardProps } from './types';
import './SecondaryCard.css';
import { SecondaryCardButtonComponent, IconComponent } from '../..';

export class LandingSecondaryCard extends React.Component<LandingSecondaryCardProps> {
    render(): JSX.Element {
        const { title, text, url, icon } = this.props;

        return <a className='card-landing-secondary' href={url} target="_blank" rel="noopener noreferrer">
            <div className='card-landing-secondary-upper-section'>
                <div className='card-landing-secondary-upper-wrapper'>
                    <IconComponent width={70} height={70} name={icon} />
                </div>
            </div>

            <div className='card-landing-secondary-lower-section'>
                <div className='card-landing-secondary-lower-wrapper'>
                    <h4 className='card-landing-secondary-title'>{title}</h4>
                    <div className='card-landing-secondary-button'>
                        <SecondaryCardButtonComponent iconWidth={22} iconHeight={22} url={url} text={text} />
                    </div>
                </div>
            </div>
        </a>;
    }
}
