import * as React from 'react';
import { IconComponentProps } from "./types";
import "./Icon.css";

/**
 * Icon component.
 */
export class Icon extends React.Component<IconComponentProps> {
    public render(): JSX.Element {
        const { name, width, height } = this.props;

        return (
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            <img width={width} height={height} src={require(`../../assets/icons/${name}.svg`).default} />
        );
    }
}
