import * as React from 'react';
import { Link } from 'react-router-dom';
import './Info.css';
import { InfoCardComponent, IconComponent, ContentComponent } from '../../components';
import { InfoRouteData } from '../../data';
import { AppDataFormat } from '../../data/types';

export class Info extends React.Component {
    render(): JSX.Element {
        const cards: AppDataFormat[] = InfoRouteData;

        return (
            <ContentComponent>
                <section className='section'>
                    <Link className='header-link' to='/'>
                        <IconComponent width={50} height={50} name='arrow-left' />
                        <p className='header-text'>Back</p>
                    </Link>
                    <div className='cards-section'>
                        {cards.map((card, i) => {
                            return <div className='cards-row' key={i}>
                                <InfoCardComponent title={card.title} text={card.text} label={card.status} url={card.url} icon={card.icon} type='secondary' />
                            </div>;
                        })}
                    </div>
                </section>
            </ContentComponent>
        );
    }
}
